import { lazyLoadEnabled } from './loader'
import { isPlaceholder } from './placeholder'
import { NWComponent, VariationProperties, Component, Project } from './types'

export const CONTAINER_NOT_FOUND = 'containerNotFound'

/**
 * Sets the attributes and values of a given Element
 */
export function setAttributes(element: NWComponent, attributes: VariationProperties): void {
  for (const attribute in attributes) {
    if (!isNaN(parseFloat(attribute))) {
      continue
    }
    const value = attributes[attribute]
    if (typeof value === 'object') {
      setAttributes(element, value as VariationProperties)
    } else {
      element.setAttribute(attribute, value)
    }
  }
}

/**
 * Creates WC element and assigns its attributes
 */
export function createElement(
  component: Component,
  variationId: number,
  order: number
): NWComponent {
  const element: NWComponent = (component.el = document.createElement(
    component.module
  ) as NWComponent)
  element.variationId = variationId
  // Set the attributes
  if (typeof component.uuid === 'undefined') {
    console.log("Error - Web Component Loader - can't find uuid in ", component.module)
  }
  const { properties } = component.variations[variationId]
  properties.id = component.uuid
  properties['data-wc-order'] = order.toString()
  setAttributes(element, properties)
  if (lazyLoadEnabled) {
    addMinHeight(component)
  }
  element.setAttribute('data', JSON.stringify(properties))
  if (component.sectionId != null) {
    element.dataset.id = component.sectionId
  }
  return element
}

/**
 * improves CLS and lazy load reliability
 * display:block because the min-height can't affect inline-block
 * which is the default web component value
 */
function addMinHeight(component: Component): void {
  if (component.minHeight != null && component.minHeightMobile != null) {
    const style = document.createElement('style')
    style.textContent = `
      #${component.uuid} {
        min-height: ${component.minHeightMobile}px;
        display:block;
      }
      @media (min-width: 768px) {
        #${component.uuid} {
          min-height: ${component.minHeight}px;
          display:block;
        }
      }
    `
    document.body.appendChild(style)
  }
}

export async function getProjectContainer(projectId: string): Promise<HTMLElement | null> {
  let projectContainer = document.getElementById(projectId)
  if (!projectContainer) {
    /**
     * this procedure waits for the project container to appear for 8 sec
     * it checks every 450ms
     * either the container is found and appendVariation continue or
     * after the timeout, if the promise rejects, appendVariation is cancelled
     */
    try {
      /**
       * unit test raise a lint error if this promise is not typed as void and
       * resolve is without arguments
       */
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
      ;(await new Promise((resolve, reject) => {
        const containerTimeout = setTimeout(() => {
          console.log("can't find project's container before timeout", projectId)
          clearInterval(containerInterval)
          reject()
        }, 8000)
        const containerInterval = setInterval(() => {
          projectContainer = document.getElementById(projectId)
          if (projectContainer) {
            clearInterval(containerInterval)
            clearTimeout(containerTimeout)
            resolve()
          }
        }, 450)
      })) as void
    } catch (e) {
      return null
    }
  }
  return projectContainer
}

/**
 * Appends or inserts the variation element to the project's container
 */
export async function appendVariation(
  projectId: string,
  component: Component,
  newVariation: NWComponent,
  order: number
): Promise<void> {
  const projectContainer = (await getProjectContainer(projectId)) as HTMLElement
  const existingVariation = document.getElementById(component.uuid)

  if (existingVariation) {
    ;(existingVariation.parentElement as HTMLElement).insertBefore(newVariation, existingVariation)
    existingVariation.remove()
  } else {
    const nextSibling = getNextSibling(projectContainer, order)
    if (nextSibling) {
      component.el = projectContainer.insertBefore(newVariation, nextSibling)
    } else {
      if (projectContainer.lastElementChild && isPlaceholder(projectContainer.lastElementChild)) {
        component.el = projectContainer.insertBefore(
          newVariation,
          projectContainer.lastElementChild
        )
      } else {
        component.el = projectContainer.appendChild(newVariation)
      }
    }
  }
}

/**
 * returns the sibling element of a new variation if it exists.
 * If the projectContainer contains other component instances
 * the new instance needs to be inserted in the right position
 */
function getNextSibling(projectContainer: HTMLElement, order: number): Element | undefined {
  return Array.from(projectContainer.children).find((child) =>
    parseInt(child.getAttribute('data-wc-order') as string) > order ? true : false
  )
}

export function clearProjectContainer(project: Project): void {
  const projectContainer = document.getElementById(project.id)
  if (projectContainer) {
    projectContainer.innerHTML = ''
  }
}
