import { TagType } from './types'
import { oldBrowser } from './polyfills'
import { excludeList } from './helpers/excludeList'

export const alreadyLoaded: URLCache = {}
export const promiseCache: PromiseCache = {}
interface URLCache {
  [url: string]: boolean
}
interface PromiseCache {
  [url: string]: Promise<void>
}

export async function createResourceTag(url: string, tagType: TagType): Promise<void> {
  if (!url) {
    return
  }
  // set to true after the release for a day(files under /shared_res/agility/ cached for a day) if caching issue is encountered
  const cacheOverride = false 
  let filePath = url
  if (!cacheOverride) {
    const skipQuery = url.split("?")
    filePath = skipQuery[0]
  }

  
  if (promiseCache[filePath] != null) {
    return promiseCache[filePath]
  }
  return (promiseCache[filePath] = new Promise((resolve) => {
    if (alreadyLoaded[filePath] === true || excludeList(filePath)) {
      resolve()
      return
    }

    const element: HTMLScriptElement | HTMLLinkElement = document.createElement(tagType)
    if (tagType === 'script') {
      element.type = oldBrowser() ? 'text/javascript' : 'module'
        ; (element as HTMLScriptElement).src = filePath
    } else {
      ; (element as HTMLLinkElement).rel = 'stylesheet'
        ; (element as HTMLLinkElement).href = filePath
    }
    element.onerror = element.onload = () => {
      resolve()
    }
    alreadyLoaded[filePath] = true
    document.head.appendChild(element)
  }))
}
