import { WCDefinition } from './types'

// LIB: get a JSON and call a callback
// create and send the request
export function loadJSON(url: string): Promise<WCDefinition> {
  return new Promise((resolve) => {
    const xhr = new XMLHttpRequest()
    xhr.onload = function () {
      resolve(JSON.parse(xhr.responseText))
    }
    xhr.open('GET', url)
    xhr.send()
  })
}
