import { load } from '../loader'

const BREAKPOINT_XL = 1920
const BREAKPOINT_L = 996
const BREAKPOINT_M = 768

export function bootstrap(): void {
  window.nwc = window.nwc || {}
  if (window.nwc.bootstrap === true) {
    return
  }
  window.addEventListener('resize', callbackResizeFunction)

  window.addEventListener('beforeunload', function () {
    window.removeEventListener('resize', callbackResizeFunction)
  })

  window.nwc.breakpoint = getBreakpoint()
  window.nwc.bootstrap = true
}

function debounce(func: (...rest: any[]) => void, ...rest: any[]) {
  const timeout: number = rest.length > 1 && rest[1] !== undefined ? (rest[1] as number) : 300
  let timer: any
  return function () {
    clearTimeout(timer)
    timer = setTimeout(() => {
      func(...rest)
    }, timeout)
  }
}

function getBreakpoint() {
  const vw = window.innerWidth
  return vw < BREAKPOINT_M
    ? 'mobile'
    : vw >= BREAKPOINT_M && vw < BREAKPOINT_L
    ? 'tablet'
    : vw >= BREAKPOINT_L && vw < BREAKPOINT_XL
    ? 'desktop'
    : 'retina'
}

const callbackResizeFunction = debounce(function () {
  const newBreakpoint = getBreakpoint()
  if (newBreakpoint === window.nwc.breakpoint) {
    return
  }
  window.nwc.breakpoint = newBreakpoint
  console.info('NWC Loader: breakpoint change detection, projects will be reloaded.')
  void load(true)
})
