export const PLACEHOLDER_STYLE_ID = 'nwc-placeholder-style'
export const PLACEHOLDER_ID = 'nwc-loader-placeholder-'
const DEFAULT_HEIGHT = 40
const color = '#FAF9F8'

export function getPlaceholderID(projectId: string): string {
  return PLACEHOLDER_ID + projectId
}

export function getPlaceholder(projectId: string, maxHeight = DEFAULT_HEIGHT): HTMLDivElement {
  const element: HTMLDivElement = document.createElement('div')
  setPlaceholderHeight(maxHeight, maxHeight, getPlaceholderID(projectId), element)
  element.style.backgroundColor = color
  element.id = getPlaceholderID(projectId)
  return element
}

function setMinHeightStyle(
  minHeight: string,
  minHeightMobile: string,
  placeholderId: string
): void {
  let style = document.getElementById(PLACEHOLDER_STYLE_ID)
  if (style === null) {
    style = document.createElement('style')
    style.id = PLACEHOLDER_STYLE_ID
    style = document.body.appendChild(style)
  }
  style.textContent = `
    #${placeholderId} {
      min-height: ${minHeightMobile};
    }
    @media (min-width: 768px) {
      #${placeholderId} {
        min-height: ${minHeight};
      }
    }
  `
}

export function isPlaceholder(element: Element): boolean {
  return element.id.indexOf(PLACEHOLDER_ID) !== -1
}

export function setPlaceholderHeight(
  height: number | string,
  heightMobile: number | string,
  placeholderId: string,
  element?: HTMLDivElement | null
): void {
  if (element == null) {
    return
  }
  let actualHeight
  let actualHeightMobile
  if (isString(height)) {
    const vhValue = convertPXToVH(height)
    //eslint-disable-next-line @typescript-eslint/restrict-plus-operands
    actualHeight = Math.min(vhValue, DEFAULT_HEIGHT) + 'vh'
  } else {
    //eslint-disable-next-line @typescript-eslint/restrict-plus-operands
    actualHeight = height + 'vh'
  }
  if (isString(heightMobile)) {
    const vhValue = convertPXToVH(heightMobile)
    //eslint-disable-next-line @typescript-eslint/restrict-plus-operands
    actualHeightMobile = Math.min(vhValue, DEFAULT_HEIGHT) + 'vh'
  } else {
    //eslint-disable-next-line @typescript-eslint/restrict-plus-operands
    actualHeightMobile = heightMobile + 'vh'
  }
  setMinHeightStyle(actualHeight, actualHeightMobile, placeholderId)
}

function convertPXToVH(value: string): number {
  return Math.round(parseInt(value) / (document.documentElement.clientHeight / 100))
}

function isString(value: string | number): value is string {
  return typeof value === 'string' || Object.prototype.toString.call(value) === '[object String]'
}

export function clearPlaceHolder(projectId: string): void {
  const placeHolder = document.getElementById(getPlaceholderID(projectId))
  placeHolder && placeHolder.parentElement && placeHolder.parentElement.removeChild(placeHolder)
}
