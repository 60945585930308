export function addListerners(): void {
  window.nwc = window.nwc || {}
  if (window.nwc.hasListeners === true) {
    return
  }
  addMouseUser()

  document.addEventListener('keydown', keyboardUser)
  document.addEventListener('mousedown', mouseUser)
  document.addEventListener('touchstart', touchUser, false)
  window.nwc.hasListeners = true
}

function keyboardUser(e: KeyboardEvent): void {
  if (e.key === 'Tab') {
    document.documentElement.classList.add('keyboardUser')
  }
}

function addMouseUser() {
  document.documentElement.classList.add('mouseUser')
}

function mouseUser(): void {
  document.documentElement.classList.remove('keyboardUser')
  document.documentElement.classList.remove('touchUser')
  addMouseUser()
}

function touchUser(): void {
  document.documentElement.classList.remove('mouseUser')
  document.documentElement.classList.remove('keyboardUser')
  document.documentElement.classList.add('touchUser')
}
