import { MachineTech, ORIGINAL, ProductListModuleData, Project, VERTUO } from './types'

const MACHINE_TECHNO_NAME = '/machineTechno/'

const PRODUCT_LIST_NAME = 'ProductList'

export async function matchCriteria(project: Project): Promise<boolean> {
  return excludeIE(project) && (await excludeTech(project))
}

export function isRunningIE(): boolean {
  return /Trident\/|MSIE/.test(window.navigator.userAgent)
}

export function excludeIE(project: Project): boolean {
  return (
    project.options == null ||
    project.options.excludeIE == null ||
    project.options.excludeIE === false ||
    !isRunningIE()
  )
}

function isTechnoName(shouldBe: string, currentTech: string): boolean {
  return (
    currentTech.substr(currentTech.indexOf(MACHINE_TECHNO_NAME) + MACHINE_TECHNO_NAME.length) ===
    shouldBe
  )
}

async function getPLPData(): Promise<ProductListModuleData | null> {
  if (window.plpData != null) {
    return (await window.plpData).data
  } else if (window.ui != null) {
    return window.ui.find((item) => item.module === PRODUCT_LIST_NAME) as ProductListModuleData
  }
  return null
}

async function isPage(targetTech: string): Promise<boolean> {
  const plpData: ProductListModuleData | null = await getPLPData()
  if (plpData == null) return true
  const currentTechs = plpData.configuration.eCommerceData.products[0].technologies
  return currentTechs.some((currentTech) => isTechnoName(targetTech, currentTech))
}

async function neitherTech(): Promise<boolean> {
  return !(await isPage(VERTUO)) && !(await isPage(ORIGINAL))
}

function getTechOption(project: Project): MachineTech | null {
  if (project.options == null) return null
  if (project.options.onlyOriginal) {
    return ORIGINAL
  } else if (project.options.onlyVertuo) {
    return VERTUO
  } else {
    return null
  }
}

export async function excludeTech(project: Project): Promise<boolean> {
  const techOption: MachineTech | null = getTechOption(project)
  if (techOption === null) {
    return true
  }
  return (await neitherTech()) || (await isPage(techOption))
}
