import { Project, Component, ComponentPreload } from './types'
import { createResourceTag } from './resource-tag'
import { loadPolyfills, oldBrowser } from './polyfills'
import { isProjectLoaded, clearProjectFromCache, clearProjectsCache } from './project-cache'
import { loadWC, isLoaded } from './web-component'
import { createElement, appendVariation, clearProjectContainer } from './component-element'
import { matchCriteria } from './options'
import { clearViewportPromises, displayPlaceholder, isCloseToViewport } from './lazy-load'
import { clearPlaceHolder, isPlaceholder } from './placeholder'

window.nwc = window.nwc || {}
window.nwc.replace = replace
window.nwc.load = load
window.nwc.updateVariation = updateVariation
window.nwc.importComponent = importComponent
window.nwc.isComponentLoaded = isComponentLoaded

const WEB_COMPONENT_PROJECTS_LOADED = 'WEB_COMPONENT_PROJECTS_LOADED'
const WEB_COMPONENT_ANCHOR_LOADED = 'WEB_COMPONENT_ANCHOR_LOADED'
const WEB_COMPONENT_FIRST_COMPONENT_DISPLAYED = 'WEB_COMPONENT_FIRST_COMPONENT_DISPLAYED'
const LAZY_LOAD_ENABLED_PARAM = 'lazyLoadEnabled'
const LAZY_LOAD_DISABLE_REGEX = 'order\/capsules\/(original|vertuo)\/[a-z\d-]+'
const PROJECTS_LOADED_TIMEOUT_DELAY = 500

const BASE_URL = '/shared_res/agility/'

let firstComponentLoaded = false
export let lazyLoadEnabled = false
let projectLoadedTimeout: number | null

export let start = Date.now()
export let anchor = ''

export async function importComponent(path: string): Promise<void> {
  return loadWC(BASE_URL + path)
}

export function isComponentLoaded(path: string): boolean {
  return isLoaded(BASE_URL + path)
}

/**
 * Initializes the nwc.projects
 * called by the loader's loader tag once the loader is loaded
 */
export async function load(clearCache = false): Promise<void> {
  readLazyLoadOption()
  anchor = getAnchor()
  await loadPolyfills()
  void createResourceTag('/shared_res/agility/web-components/assets/css/fonts.css', 'link')
  await loadProjects(null, clearCache)
  //here the projects are all loaded / ready / pushed on screen
  dispatchProjectLoaded()
}

function dispatchProjectLoaded(): void {
  if (projectLoadedTimeout != null) {
    clearTimeout(projectLoadedTimeout)
    projectLoadedTimeout = null
  }
  projectLoadedTimeout = window.setTimeout(
    () => window.dispatchEvent(createEvent(WEB_COMPONENT_PROJECTS_LOADED)),
    PROJECTS_LOADED_TIMEOUT_DELAY
  )
}

function dispatchAnchorLoaded(): void {
  if (projectLoadedTimeout != null) {
    clearTimeout(projectLoadedTimeout)
    projectLoadedTimeout = null
  }
  projectLoadedTimeout = window.setTimeout(
    () => window.dispatchEvent(createEvent(WEB_COMPONENT_ANCHOR_LOADED)),
    PROJECTS_LOADED_TIMEOUT_DELAY
  )
}

/**
 * this is reading first the global variable (low priority)
 * then the query param
 * The query param is considered true is anything else then "false"
 */
function readLazyLoadOption(): void {
  lazyLoadEnabled =
    window.nwc.lazyLoadEnabled != null ? window.nwc.lazyLoadEnabled : lazyLoadEnabled
  const params = new URLSearchParams(window.location.search)
  lazyLoadEnabled = params.has(LAZY_LOAD_ENABLED_PARAM)
    ? params.get(LAZY_LOAD_ENABLED_PARAM) !== 'false'
    : lazyLoadEnabled
  // TODO: properly fix all PDP, dirty switch off
  if (lazyLoadEnabled) {
    const {pathname} = window.location
    lazyLoadEnabled = !(new RegExp(LAZY_LOAD_DISABLE_REGEX, 'g').test(pathname))
  }
}

/**
 * Loads all projects
 */
// tslint:disable-next-line
export async function loadProjects(e: Event | null, clearCache = false): Promise<void> {
  if (clearCache) clearProjects()
  if (window.nwc && window.nwc.projects) {
    await Promise.all(window.nwc.projects.map(loadProject))
  }
}

function clearProjects() {
  clearPlaceHolders()
  clearViewportPromises()
  clearProjectsContainers()
  clearProjectsCache()
}

function clearPlaceHolders(): void {
  window.nwc &&
    window.nwc.projects &&
    window.nwc.projects.forEach((project) => clearPlaceHolder(project.id))
}

function clearProjectsContainers() {
  window.nwc.projects &&
    window.nwc.projects.forEach((project) => {
      const container = document.getElementById(project.id)
      if (container) {
        Array.from(container.children)
          .filter((child) => !isPlaceholder(child))
          .forEach((child) => (child.parentElement as Element).removeChild(child))
      }
    })
}

/**
 * Loads the given project
 */
async function loadProject(project: Project): Promise<void> {
  const matches = await matchCriteria(project)
  const projectContainer = document.getElementById(project.id)
  if (isProjectLoaded(project) || !project.components || !matches) {
    if (!matches && projectContainer) {
      clearCLSSize(projectContainer, project)
    }
    return
  }
  start = Date.now()
  earlyLoadResources(project)
  const withAnchor = project.components.findIndex(
    (component: Component) => component?.sectionId === anchor
  )

  const componentPromises = project.components.map((component: Component, index: number) => {
    // this makes sure we do not lazy load sections up to an anchor
    const shouldPass = index <= withAnchor
    return loadComponent(project.id, component, index, project, shouldPass)
  })
  if (lazyLoadEnabled) {
    await new Promise((resolve: (value?: unknown) => void) => {
      setTimeout(() => {
        resolve()
      }, 0)
    })
    void displayPlaceholder(project)
  }

  if (withAnchor !== -1) {
    const anchorAndAbove = componentPromises.concat()
    anchorAndAbove.length = withAnchor + 1
    void Promise.all(anchorAndAbove).then(() => dispatchAnchorLoaded())
  }
  await Promise.all(componentPromises)
  projectContainer && clearCLSSize(projectContainer, project)
}

function clearCLSSize(projectContainer: HTMLElement, project: Project): void {
  projectContainer.classList.remove(project.id)
}

// optimize attempt, load all the resources early
function earlyLoadResources(project: Project) {
  const browserIsOld = oldBrowser()
  if (project.stylesheets) {
    project.stylesheets.forEach((styleSheet) => {
      void createResourceTag(styleSheet, 'link')
    })
  }
  if (browserIsOld) {
    if (project.nomodule) {
      project.nomodule.forEach((script) => {
        void createResourceTag(script, 'script')
      })
    }
  } else {
    if (project.modules) {
      project.modules.forEach((module) => {
        void createResourceTag(module, 'script')
      })
    }
  }
  if (project.preload && !lazyLoadEnabled) {
    Object.values(project.preload).forEach((component) => {
      if (browserIsOld) {
        void createResourceTag(browserIsOld ? component.legacy : component.modern, 'script')
      }
      void createResourceTag(component.style, 'link')
    })
  }
}

/**
 * This is an ugly hack because some element had historically used the
 *  ID attributes that now reside in each variation properties
 * But the loader has its own ID for each component which collides with
 * the variations ID
 */
function correctId(component: Component): void {
  const { properties } = component.variations[0]
  if (properties.id) {
    component.uuid = properties.id as string
    component.variations.forEach((variation) => delete variation.properties.id)
  }
}

/**
 * Loads a given project's component
 * @param order order of this component within the project
 */
async function loadComponent(
  projectId: string,
  component: Component,
  order: number,
  project: Project,
  isAnchor: boolean
): Promise<void> {
  correctId(component)

  let promiseResolve
  if (lazyLoadEnabled) {
    try {
      promiseResolve = await isCloseToViewport(project, order, isAnchor)
    } catch (e) {
      console.log('Error in lazy load, project', project.id, 'component', order)
    }
    const preload: ComponentPreload | null = flattenDeps(project, component)
    /**
     * this promise await will wait until this wc is ready for use
     * (CSS + JS loaded)
     * before instancing the element
     * */
    await loadWC(component.folder, preload)
  } else {
    await loadWC(component.folder)
  }

  const { variations } = component
  if (variations.length > 1 || variations[0].personalisation != null) {
    //load casper chunk only when this component has variations
    const { createCasperExperience } = await import(
      /* webpackChunkName: "casper" */ './casper'
    )
    createCasperExperience(projectId, component, order, promiseResolve)
    promiseResolve && promiseResolve()
  } else {
    displayNewVariation(projectId, component, 0, order, promiseResolve)
  }
}

/**
 * Handles the preload property from the project
 * the current module's entry contains its direct resources
 *  + a list of modules dependencies
 * It will append each dependency resources to the preload
 *  propery value and return it
 * @param project
 * @param component
 */
function flattenDeps(project: Project, component: Component): ComponentPreload | null {
  let preload: ComponentPreload | null = null

  if (project.preload != null && project.preload[component.module] != null) {
    preload = project.preload[component.module]
    if (preload.deps != null) {
      preload.deps = preload.deps.map((dep) => {
        const module = dep as string
        if (project.preload != null && project.preload[module] != null) {
          const moduleResources = project.preload[module]
          if (moduleResources != null) {
            return {
              module,
              ...moduleResources,
            }
          }
        }
        return module
      })
    }
  }
  return preload
}

/**
 * Creates and inserts a component into its project's container
 */
export function displayNewVariation(
  projectId: string,
  component: Component,
  variationId: number,
  order: number,
  resolve: ((value: void | PromiseLike<void>) => void) | undefined = undefined
): void {
  void appendVariation(projectId, component, createElement(component, variationId, order), order)
  if (!firstComponentLoaded) {
    firstComponentLoaded = true
    window.dispatchEvent(createEvent(WEB_COMPONENT_FIRST_COMPONENT_DISPLAYED))
  }
  resolve && resolve()
}

/**
 * returns the given project position in nxc.projects:[]
 */
function findProjectIndex(projectId: string) {
  if (window.nwc.projects) {
    return window.nwc.projects.findIndex((project) => project.id === projectId)
  } else {
    return -1
  }
}

/**
 * Replaces a project by an other and initializes it
 */
export async function replace(project: Project): Promise<void> {
  if (!window.nwc.projects || !matchCriteria(project)) {
    return
  }
  const index = findProjectIndex(project.id)
  if (index < 0) {
    window.nwc.projects.push(project)
  } else {
    clearProjectFromCache(project)
    clearProjectContainer(project)
    window.nwc.projects[index] = project
  }
  await loadProject(project)
}

/**
 * Create an event compatible with IE
 * @param eventName
 */
function createEvent(eventName: string) {
  let event: Event
  if (typeof Event === 'function') {
    event = new Event(eventName)
  } else {
    event = document.createEvent('Event')
    event.initEvent(eventName, true, true)
  }
  return event
}

/**
 * retro compatibility function (missing projectId)
 * this function needs to find the project, then the component
 * index (display order), then the component before calling
 * displayNewVariation with these arguments
 */
function updateVariation(componentId: string, variationId: number) {
  if (!window.nwc.projects) return
  let component: Component | null = null
  let componentIndex = -1
  const project = window.nwc.projects.find((project) => {
    componentIndex = project.components.findIndex((component) => component.uuid === componentId)
    if (componentIndex !== -1) {
      component = project.components[componentIndex]
      return true
    } else {
      return false
    }
  })
  if (!project || !component || componentIndex === -1) return
  displayNewVariation(project.id, component, variationId, componentIndex)
}

function getAnchor(): string {
  return window.location.hash.substring(1)
}
