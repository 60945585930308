import { WCDefinition, WCCache, ComponentPreload, ComponentResources } from './types'
import { loadJSON } from './json'
import { createResourceTag } from './resource-tag'
import { oldBrowser } from './polyfills'
import { excludeList } from './helpers/excludeList'

export const wcCache: WCCache = {}
const fileName = 'package.json'
/**
 * Returns a promise resolving when the given Web Component is ready
 * Maps Web Components by path
 * @param path path of the given WC's package.json (without file name)
 */
export async function loadWC(path: string, preload: ComponentPreload | null = null): Promise<void> {
  if (isLoaded(path)) {
    return wcCache[path].ready
  } else {
    return new NWC().loadDef(path, preload)
  }
}

export function isLoaded(path: string): boolean {
  return wcCache[path] != null
}
export default class NWC {
  public ready?: Promise<void>
  /**
   * Checks that it returns directly the promise to be able to await from multiple place the readiness of the webcomponent
   */
  public async loadDef(path: string, preload: ComponentPreload | null): Promise<void> {
    wcCache[path] = this
    this.ready = this.load(path, preload)
    return await this.ready
  }
  /**
   * Loads all the necessary resources for one given WC
   */
  private async load(path: string, preload: ComponentPreload | null): Promise<void> {
    try {
      
      if (excludeList(path)){
        return
      }

      if (preload !== null) {
        //this will start loading the scripts before receiving package.json
        void this.loadTags(preload)
      }
      const definition: WCDefinition = await loadJSON(path + fileName)
      const { stylesheet: style, module: modern, nomodule: legacy } = definition.scripts
      await Promise.all([
        await this.loadDeps(definition),
        await this.loadTags({ style, modern, legacy }),
      ])
    } catch (e) {
      throw new Error('Error - WebComponent.load ' + (e as Error).message)
    }
  }

  private async loadDeps(definition: WCDefinition): Promise<void> {
    // Check if component has dependencies
    if (definition.scriptDependencies) {
      // Load dependencies
      await Promise.all(definition.scriptDependencies.map(this.loadDep.bind(this)))
    }
  }

  private async loadDep(path: string): Promise<void> {
    return loadWC(path, null)
  }

  /**
   * Optionally the preload argument can contain deps which need to be individually preloaded
   * @param deps
   */
  private async preloadDeps(
    deps: ComponentResources[] | undefined
  ): Promise<void | [void, void][]> {
    if (deps != null) {
      return Promise.all(
        deps.map(({ style, modern, legacy }) => {
          return Promise.all([
            createResourceTag(oldBrowser() ? legacy : modern, 'script'),
            createResourceTag(style, 'link'),
          ])
        })
      )
    } else {
      return
    }
  }

  private async loadTags(
    resources: ComponentPreload
  ): Promise<[void | [void, void][], void, void]> {
    const { style, modern, legacy } = resources
    return Promise.all([
      this.preloadDeps(resources.deps as ComponentResources[] | undefined),
      createResourceTag(oldBrowser() ? legacy : modern, 'script'),
      createResourceTag(style, 'link'),
    ])
  }
}
