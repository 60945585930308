import { CONTAINER_NOT_FOUND, getProjectContainer } from './component-element'
import { getPlaceholder, getPlaceholderID, setPlaceholderHeight } from './placeholder'
import { Component, Project, ViewportCache } from './types'

let viewportCache: ViewportCache = {}

const MINIMUM_COMPONENT_DISPLAYED = 2
const VIEWPORT_BOTTOM_MARGIN = '600px'
const OBSERVEE_THRESHOLD = 0.8
const PROJ_CONTAINER_THRESHOLD = 0.1

/**
 * Main API entry
 *
 * will resolves when the given component is ready to be displayed
 * acts as a pause in the loader's main rendering loop at the project level
 *
 * the spec are, the component should render if the previous component is
 * visible in proportion set by OBVSERVEE_THRESHOLD inside the viewport
 *  augmented by VIEWPORT_BOTTOM_MARGIN
 *
 * @param project
 * @param order
 */
export async function isCloseToViewport(
  project: Project,
  order: number,
  passLazy: boolean
): Promise<(value: void | PromiseLike<void>) => void> {
  const { promise, resolve: resolvePromise } = deferred<void>()

  cacheViewportPromise(project.id, order, promise)
  //waits one tick to let all the project's component create their own promise
  await new Promise((resolve: (value?: unknown) => void) => {
    setTimeout(() => {
      resolve()
    }, 0)
  })

  if (order > MINIMUM_COMPONENT_DISPLAYED && !passLazy) {
    //awaits rendering of all prior element in the project
    await Promise.all(getViewportPromises(project.id, order - 1))
  }
  await isPreviousInViewport(project, order)
  return resolvePromise
}

/**
 * resolves when the section before in the project is
 * visible enough to trigger the current component
 * render
 *
 * @param project
 * @param order
 */
async function isPreviousInViewport(project: Project, order: number): Promise<void> {
  let previous: HTMLElement | null
  let isContainer = false
  if (order <= MINIMUM_COMPONENT_DISPLAYED) {
    const projectContainer: HTMLElement | null = await getProjectContainer(project.id)
    if (projectContainer === null) {
      throw CONTAINER_NOT_FOUND
    }
    previous = projectContainer
    isContainer = true
  } else {
    previous = project.components[order - 1].el as HTMLElement
  }
  if (previous != null) {
    await isElementInViewport(previous, isContainer)
  }
}

/**
 * resolves when the targeted section is visible enough
 *
 * @param element
 */
async function isElementInViewport(element: HTMLElement, isContainer = false): Promise<void> {
  return new Promise((resolve) => {
    const obs = new IntersectionObserver(
      (entries, observer) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            observer.disconnect()
            resolve()
          }
        })
      },
      {
        threshold: isContainer ? PROJ_CONTAINER_THRESHOLD : OBSERVEE_THRESHOLD,
        rootMargin: `10000px 0px ${VIEWPORT_BOTTOM_MARGIN} 0px`,
      }
    )
    obs.observe(element)
  })
}

/**
 * adds a placeholder rectangle at the bottom of the project's container
 *  until the project has finished loading/rendering
 */
export async function displayPlaceholder(project: Project): Promise<void> {
  const projectContainer = await getProjectContainer(project.id)
  if (projectContainer == null) {
    return
  }
  let placeHolder = getPlaceholder(project.id)
  placeHolder = projectContainer.appendChild(placeHolder)
  const promises = getViewportPromises(project.id)
  for (let i = 0; i < promises.length; i++) {
    await promises[i]
    if (i === project.components.length - 2) {
      const nextComponent: Component = project.components[i + 1]
      if (nextComponent.minHeight != null && nextComponent.minHeightMobile != null) {
        setPlaceholderHeight(
          nextComponent.minHeight,
          nextComponent.minHeightMobile,
          getPlaceholderID(project.id)
        )
      }
    }
  }
  await Promise.all(promises)
  placeHolder.parentElement && placeHolder.parentElement.removeChild(placeHolder)
}

function cacheViewportPromise(projectId: string, order: number, promise: Promise<void>): void {
  if (!viewportCache[projectId]) {
    viewportCache[projectId] = []
  }
  viewportCache[projectId][order] = promise
}

// returns all rendering promises equal or prior to given order (index)
function getViewportPromises(projectId: string, order?: number): Promise<void>[] {
  if (order == null) {
    return viewportCache[projectId]
  }
  return viewportCache[projectId].filter((_, index) => index <= order)
}

export function clearViewportPromises(): void {
  viewportCache = {}
}

const deferred = <T>(): {
  resolve: (value: T | PromiseLike<T>) => void
  reject: (reason?: any) => void
  promise: Promise<T>
} => {
  let resolve!: (value: T | PromiseLike<T>) => void
  let reject!: (reason?: any) => void
  const promise = new Promise<T>((res, rej) => {
    resolve = res
    reject = rej
  })

  return {
    resolve,
    reject,
    promise,
  }
}
