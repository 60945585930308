import NWC from './web-component'

export const VERTUO = 'vertuo'
export const ORIGINAL = 'original'

export {}

declare global {
  interface Window {
    nwc: NespressoWebComponent
    casperEventHub?: CasperEventHub
    gtmDataObject?: GTMDataObjectItem[]
    ui?: CremaModuleData[]
    plpData: Promise<PLPData>
    WeakRef?: () => void
  }
}

export interface PLPData {
  techno: string
  data: ProductListModuleData
}

export interface CremaModuleData {
  module: string
  id: string
  configuration: {
    eCommerceData: Record<string, unknown>
  }
}

export type ViewportCache = Record<string, ProjectViewportCache>
export type ProjectViewportCache = ComponentViewportCache[]
export type ComponentViewportCache = Promise<void>

export interface ProductListModuleData extends CremaModuleData {
  configuration: {
    eCommerceData: {
      categories: Category[]
      productGroups: ProductGroup[]
      products: [Product, ...Product[]]
    }
  }
}

export interface Product {
  technologies: string[]
}

export interface ProductGroup {
  categoryId: string
  productIds: string[]
}

export interface Category {
  id: string
  name: string
  description: string
  subCategories: string[]
  superCategories: string[]
}

export interface GTMDataObjectItem {
  event: string
  component_name: string
  experience_name: string
  experience_rule?: string
  experience_match: string
  event_raised_by: string
}

interface NespressoWebComponent {
  bootstrap?: boolean
  breakpoint?: string
  updateVariation?: (componentId: string, variationId: number) => void
  load?: (clearCache: boolean | undefined) => Promise<void>
  projects?: Project[]
  replace?: (project: Project) => void
  importComponent?: (path: string) => Promise<void>
  isComponentLoaded?: (path: string) => boolean
  hasListeners?: boolean
  lazyLoadEnabled?: boolean
}

export interface Project {
  id: string
  version: string
  created: string
  stylesheets?: string[]
  modules?: string[]
  nomodule?: string[]
  preload?: Preload
  components: Component[]
  options?: ProjectOptions
}

export type Preload = Record<string, ComponentPreload>

export interface ComponentResources {
  style: string
  modern: string
  legacy: string
}

export type ComponentPreload = ComponentResources & {
  deps?: ((ComponentResources & { module: string }) | string)[]
}

interface ProjectOptions {
  excludeIE?: boolean
  onlyOriginal?: boolean
  onlyVertuo?: boolean
}

export type MachineTech = typeof ORIGINAL | typeof VERTUO | string

export interface Component {
  uuid: string
  sectionId?: string
  module: string
  folder: string
  version: string
  variations: Variation[]
  el?: NWComponent
  minHeight?: string
  minHeightMobile?: string
}

export interface NWComponent extends HTMLElement {
  variationId: number
}

export interface Variation {
  index: number
  properties: VariationProperties
  personalisation?: Personalization | null
}

export interface VariationProperties {
  [propName: string]: string | VariationProperties | string[]
}

export interface Personalization {
  name: string
  rules: Rule[]
}

export interface Rule {
  type: string
  condition: string
}

export interface WCDefinition {
  name: string
  version: string
  description: string
  folder: string
  author: string
  preview: string
  scriptDependencies?: string[]
  scripts: WCScripts
  tag: string
}
interface WCScripts {
  stylesheet: string
  nomodule: string
  module: string
}

export type TagType = 'link' | 'script'

interface CasperEventHub {
  Experience: ExperienceConstructor
  allOf: Predicate
  oneOf: Predicate
  any: Predicate
  isGreaterThanOrEqual: Predicate
  isLessThanOrEqual: Predicate
  noneOf: Predicate
  olderThan: Predicate
  regex: Predicate
}

/**
 * Casper Event Hub Types
 */
export type Primitive = string | number | boolean | IUnknownObject | string[] | number[] | Predicate
export interface IUnknownObject {
  [index: string]: Primitive | undefined
}
export type Predicate = (
  possibilities: Possibilities,
  ...args: Array<Predicate | Possibilities>
) => boolean
export type Predicates = Array<Predicate>
export type Condition = Primitive | Predicate | IUnknownObject
export type Conditions = Array<Condition> & IndexableArray
export type Possibility = Primitive | Primitive[]
export type Possibilities = Array<Possibility>
export type IndexableArray = {
  [index in ArrayTestMethods]: (callback: (item: Condition) => boolean) => boolean
}
export type ArrayTestMethods = 'every' | 'some'
/**
 * End of Casper Event Hub Types
 */

export interface ExperienceType {
  executeOnMatch: (callback: (data: ExperienceArg) => void) => ExperienceType
  executeOnMismatch: (callback: (data: ExperienceArg) => void) => ExperienceType
  evaluate: () => ExperienceType
}
export interface ExperienceArg {
  unsubscribe: () => void
}

export interface CasperRule {
  args: any[]
}

interface ExperienceConstructor {
  new(name: string, obj?: ParamExperience): ExperienceType
}

export interface ProjectCache {
  [id: string]: boolean
}

export interface WCCache {
  [id: string]: NWC
}

interface ParamExperience {
  noTracking: boolean
}
