import { createResourceTag } from './resource-tag'
import { loadProjects } from './loader'
let isModern: boolean
const win = window

export async function loadPolyfills(): Promise<void> {
  if (oldBrowser()) {
    win.addEventListener('WebComponentsReady', loadProjects)
    win.addEventListener('beforeunload', function () {
      win.removeEventListener('WebComponentsReady', loadProjects)
    })
    const script = 'script'
    await Promise.all([
      createResourceTag('https://cdn.polyfill.io/v2/polyfill.min.js', script),
      createResourceTag(
        'https://unpkg.com/@webcomponents/webcomponentsjs@latest/webcomponents-loader.js',
        script
      ),
      createResourceTag('https://unpkg.com/@webcomponents/custom-elements', script),
      createResourceTag(
        'https://unpkg.com/@webcomponents/webcomponentsjs/custom-elements-es5-adapter.js',
        script
      ),
      createResourceTag(
        'https://polyfill.io/v3/polyfill.min.js?features=Array.prototype.at',
        script
      ),
    ])
  }
}

export function oldBrowser(): boolean {
  // cache result, call only once
  return false
  /*
  if (isModern == null) {
    isModern = isModernBrowser()
  }
  return !isModern
  */
}

function isModernBrowser(): boolean {
  return (
    ('at' in []) &&
    supportsDynamicImport() &&
    Promise.prototype.finally != null &&
    supportsLiterals() &&
    supportsClassFields() &&
    Promise.any != null &&
    window.WeakRef != null &&
    String.prototype.replaceAll != null &&
    supportsChaining()
  )
}

const dynamicImport = 'import("")'
const literals = '""===``'
const classFields = 'class WithFields{ #privateField; instanceField; }'
const optionalChaining = 'const foo = {}; foo?.bar'

function testCode(code: string): boolean {
  try {
    // eslint-disable-next-line @typescript-eslint/no-implied-eval
    new Function(code)
    return true
  } catch (err) {
    return false
  }
}

function supportsDynamicImport() {
  return testCode(dynamicImport)
}

function supportsLiterals(): boolean {
  return testCode(literals)
}

function supportsClassFields(): boolean {
  return testCode(classFields)
}

function supportsChaining(): boolean {
  return testCode(optionalChaining)
}
