import { ProjectCache, Project } from './types'

export const loadedProjects: ProjectCache = {}

export function isProjectLoaded(project: Project): boolean {
  if (loadedProjects[project.id] != null) {
    return true
  } else {
    loadedProjects[project.id] = true
    return false
  }
}

export function clearProjectFromCache(project: Project): void {
  delete loadedProjects[project.id]
}

export function clearProjectsCache(): void {
  for (const project in loadedProjects) delete loadedProjects[project]
}
